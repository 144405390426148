import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import CaseStudyRoll from "../components/CaseStudyRoll";
import Content, { HTMLContent } from "../components/Content";
import ProductList from "../components/ProductList";

export const SolutionDetailTemplate = ({
  helmet,
  title,
  image,
  service,
  relatedCaseStudy,
  relatedProduct,
  content,
  contentComponent,
}) => {
  const PageContent = contentComponent || Content;
  return (
    <>
      {helmet || ""}
      <div className="hero bg-indigo-800">
        <div className="max-w-screen-xl md:flex md:items-center md:justify-between md:py-32 mx-auto pt-8 pb-16 px-6">
          <div className="font-serif leading-snug max-w-xl mb-6 md:text-6xl text-2xl text-white">
            <h1>{title}</h1>
          </div>
          <article className="flex-shrink-0 md:pl-6 md:w-1/2 w-full">
            <PreviewCompatibleImage imageInfo={image} />
          </article>
        </div>
      </div>
      <div className="bg-gray-100">
        <div className="max-w-screen-xl md:flex md:justify-between md:py-32 mx-auto px-6 py-10">
          <div className="md:w-1/2 md:mr-10 w-11/12">
            <PageContent className="text-content" content={content} />
          </div>
          {service && (
            <div className="md:text-lg md:w-2/5 w-11/12">
              <h2 className="font-medium mb-5 md:mb-6 md:mt-3 mt-10 text-indigo-900">{service.keyfeatures.title}</h2>
                <ul className="list-disc md:w-3/4 ml-4 text-xl text-blue-300">
                {service.keyfeatures.features.map((feature, i) => (
                  <li className="mb-6 md:mb-8 pl-1" key={i}>
                    <p className="md:leading-loose md:text-lg text-base text-gray-800">
                      {feature.item}
                    </p>
                  </li>
                  ))}
                </ul>
            </div>
          )}
        </div>
      </div>
      <div className="bg-white pb-56">
        <div className="max-w-screen-xl md:pt-16 mx-auto pt-6">
          <ProductList nestedIn='solution-detail' relatedProduct={relatedProduct} />
          <div className="md:mt-20 md:pb-32 md:relative mt-6 pb-20 px-4 py-12">
            <h2
              className="border-t border-gray-300 font-serif mb-6 md:mb-10 md:text-5xl pt-10 text-2xl text-indigo-900"
            >
              Related work
            </h2>
            <CaseStudyRoll
                relatedCaseStudy={relatedCaseStudy}
                startPos={0}
                limit={2}
            />
            <Link
              className="border-2 border-blue-300 focus:outline-none focus:bg-blue-300 font-medium hover:bg-blue-300 inline-block md:absolute md:mr-3 md:right-0 md:mt-24 md:top-0 mt-4 py-4 px-6 rounded-full text-indigo-900"
              to="/case-studies">
              View case studies
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

SolutionDetailTemplate.propTypes = {
  title: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  service: PropTypes.shape({
    keyfeatures: PropTypes.shape ({
      title: PropTypes.string,
      features: PropTypes.array,
    }),
  }),
  relatedCaseStudy: PropTypes.shape ({
    featuredtitle: PropTypes.array,
  }),
  relatedProduct: PropTypes.array
};

const SolutionDetail = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <SolutionDetailTemplate
        title={post.frontmatter.title}
        image={post.frontmatter.image}
        service={post.frontmatter.service}
        relatedCaseStudy={post.frontmatter.relatedCaseStudy}
        relatedProduct={post.frontmatter.relatedProduct}
        content={post.html}
        contentComponent={HTMLContent}
        helmet={
          <Helmet>
            <title>{post.frontmatter.title} | CVision AI</title>
          </Helmet>
        }
      />
    </Layout>
  );
};

SolutionDetail.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default SolutionDetail;

export const solutionPageQuery = graphql`
  query SolutionDetail($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 600, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        service {
          keyfeatures {
            title
            features {
              item
            }
          }
        }
        relatedCaseStudy {
          featuredtitle
        }
        relatedProduct {
          title
        }
      }
    }
  }
`;
